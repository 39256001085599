import { UserSettingsService } from "@services/user-settings/user-settings.service";
import { Injectable } from "@angular/core";
import {
  Team,
  RankMethod,
  SelectionType,
  StrengthOfScheduleMethodology,
} from "@models";
import { Helpers } from "@helpers/helpers";
import * as Enumerable from "linq";
import { LeagueSettingsService } from "../league-settings/league-settings.service";
import { GameMapService } from "../game-map/game-map.service";
import { WeekService } from "../week/week.service";
@Injectable()
export class TiebreakerService {
  constructor(
    private userSettings: UserSettingsService,
    private leagueSettings: LeagueSettingsService,
    private gameMapService: GameMapService,
    private weekService: WeekService
  ) {}
  teamSchedules = {};
  isDraftOrderRanking = false;
  skipDivisionRank = false;
  updateStandings(teams: Team[], conf1: string, conf2: string): any {
    this.setSovAndSos(teams);
    this.updateRankings(
      teams.filter((p) => p.conference === conf1),
      false
    );
    this.updateRankings(
      teams.filter((p) => p.conference === conf2),
      false
    );
    this.updateRecordVsPlayoffTeams(teams);
  }
  conferencePickBottom(teams: Team[]) {
    if (!Enumerable.from(teams).any((p) => p == undefined)) {
      let top = Helpers.worstPctNaN(teams);
      if (top.length == 1) {
        return top[0];
      } else {
        return this.draftOrderTiebreaker(top);
      }
    }
    return;
  }
  draftOrderTiebreaker(teams: Team[]) {
    let remaining = teams.slice(0);
    if (!Enumerable.from(teams).any((p) => p == undefined)) {
      //sos
      remaining = Helpers.worstSOS(remaining);
      if (remaining.length == 1) {
          return remaining[0];
      }
      if (remaining.length != teams.length) {
          return this.draftOrderTiebreaker(remaining);
      }
      //if we can break with a Division, we need to
      if (Enumerable.from(teams).groupBy((p) => p.division).toArray().length !== teams.length) {
          //Slice it up into Divisions, order by the number of divisions. This will let us eliminate Ties easier
          let Divisions = Enumerable.from(teams).groupBy((p) => p.division)
          .orderByDescending((p) => p.toArray().length)
          .toArray();
          let top = [];
          for (let Division of Divisions) {
              let item = this.divisionPickBottom(Division.getSource());
              item.SkipRanking = true;
              top.push(item);
          }
          remaining = Enumerable.from(top).where((p) => p != undefined).toArray();
          if (remaining.length == 1) {
              return remaining[0];
          }
          if (remaining.length == 0) return;
      }
      else {
          remaining = teams.slice(0);
      }

      if (Enumerable.from(remaining).all((p: any) => p.Conference == remaining[0].conference
        && p.division == remaining[0].division)) {
          return this.divisionPickBottom(remaining);
      }

      if (Enumerable.from(remaining).all((p: any) => p.conference == remaining[0].conference)) {
          let top = [];
          if (remaining.length == 1) {
              return remaining[0];
          }
          while (remaining.length > 0) {
              let found = this.conferencePickTop(remaining);
              remaining.splice(remaining.indexOf(found), 1);
              top.push(found);
          }
          return top.reverse()[0];
      } else {             //we have teams in opposite conferences
          if (remaining.length == 1) {
              return remaining[0];
          }

          // hth
          remaining = Helpers.worstHtHConference(remaining, this.leagueSettings);
          if (remaining.length == 1) {
              return remaining[0];
          } else if (remaining.length != teams.length) {
              return this.draftOrderTiebreaker(remaining);
          }

          // cg
          remaining = Helpers.worstCommonPct(remaining, this.leagueSettings, this.userSettings.userConfig);
          if (remaining.length == 1) {
              return remaining[0];
          } else if (remaining.length != teams.length) {
              return this.draftOrderTiebreaker(remaining);
          }

          // sov
          remaining = Helpers.worstSOV(remaining);
          if (remaining.length == 1) {
              return remaining[0];
          } else if (remaining.length != teams.length) {
              return this.draftOrderTiebreaker(remaining);
          }

          return this.draftOrderCoinFlip(remaining);
      }
    }
  }
  generateDraftOrder(teams: Team[]) {
    let nonPlayoffTeams = Enumerable.from(teams)
      .where((p: Team) => p.conferenceRank > 7)
      .toArray();
    for(let team of nonPlayoffTeams){
      if(!team || !team.gameRecords || !team.gameRecords.divisionRecord){
        return;
      }
    }
    for(let team of teams){
      team.draftOrderNumber = null;
    }
    let top:Team[] = [];
    let remaining = nonPlayoffTeams.slice(0);

    while (remaining.length > 0) {
      let worst = this.conferencePickBottom(remaining);
      top.push(worst);
      remaining.splice(remaining.indexOf(worst), 1);
    }
    let losers = [];

    remaining = this.gameMapService.afcWcGames.concat(this.gameMapService.nfcWcGames)
    .filter(p => p?.selectionMade)
    .map(p => teams.find(t => t.teamId == p.teamThatLost.teamId));

    while (remaining.length > 0) {
        let best = this.conferencePickBottom(remaining);
        losers.push(best);
        remaining.splice(remaining.indexOf(best), 1);
    }
    top = top.concat(losers);

    losers = [];
    remaining = this.gameMapService.afcDvGames.concat(this.gameMapService.nfcDvGames)
    .filter(p => p?.selectionMade)
    .map(p => teams.find(t => t.teamId == p.teamThatLost.teamId));
    while (remaining.length > 0) {
        let best = this.conferencePickBottom(remaining);
        losers.push(best);
        remaining.splice(remaining.indexOf(best), 1);
    }
    top = top.concat(losers);
    losers = [];
    remaining = [this.gameMapService.afcCgGame, this.gameMapService.nfcCgGame]
      .filter(p => p?.selectionMade)
      .map(p => teams.find(t => t.teamId == p.teamThatLost.teamId));
    while (remaining.length > 0) {
        let best = this.conferencePickBottom(remaining);
        losers.push(best);
        remaining.splice(remaining.indexOf(best), 1);
    }
    top = top.concat(losers);

    if (this.gameMapService.sbGame?.selectionMade) {
        top.push(this.gameMapService.sbGame.teamThatLost);
        top.push(this.gameMapService.sbGame.teamThatWon);
    }
    for(let i = 0; i < top.length;i++){
      teams.find(p => p.teamId == top[i].teamId).draftOrderNumber = i+1;
    }

  }

  updateRankings(teams: Array<Team>, isDraftOrderRanking: boolean) {
    for (const team of teams) {
      team.tiebreakers = {
        HTH: [],
        Sweeps: [],
        DivHTH: [],
        CommonGames: [],
        Explanations: [],
      };
    }
    this.isDraftOrderRanking = isDraftOrderRanking;
    const divRanked = this.rankDivisions(
      Enumerable.from(teams)
        .groupBy((p) => p.division)
        .toArray()
    );
    let topTeams = this.conferenceRanker(
      [
        divRanked.North[0],
        divRanked.East[0],
        divRanked.South[0],
        divRanked.West[0],
      ],
      true
    );
    if (topTeams && topTeams.length > 0) {
      topTeams = this.wildcardRanker(divRanked, topTeams, [
        divRanked.North.slice(1),
        divRanked.East.slice(1),
        divRanked.South.slice(1),
        divRanked.West.slice(1),
      ]);
      let teamsSorted = this.rankRestOfConference(topTeams, teams);
      topTeams = Enumerable.from(topTeams)
        .where((p) => p !== undefined)
        .toArray();
      const top4 = Enumerable.from(topTeams).take(4).toArray();
      for (const team of top4) {
        if (team && !this.isDraftOrderRanking) {
          switch (team.RankMethod) {
            case RankMethod.allPct:
              team.RankMethod = RankMethod.divTopAllPct;
              break;
            case RankMethod.confPct:
              team.RankMethod = RankMethod.divTopConfPct;
              break;
            case RankMethod.divPct:
              team.RankMethod = RankMethod.divTopDivPct;
              break;
            case RankMethod.sos:
              team.RankMethod = RankMethod.divTopSos;
              break;
            case RankMethod.sov:
              team.RankMethod = RankMethod.divTopSov;
              break;
            case RankMethod.hth:
              team.RankMethod = RankMethod.divTopHth;
              break;
            case RankMethod.commonPct:
              team.RankMethod = RankMethod.divTopCommonPct;
              break;
            case RankMethod.confPointDifferential:
              team.RankMethod = RankMethod.divTopConfPointDifferential;
          }
          const teamIDx = teamsSorted.indexOf(team);
          if (teamIDx >= 0) {
            teamsSorted.splice(teamIDx, 1);
          }
        }
      }
      for (const team of Enumerable.from(topTeams).skip(4).take(2).toArray()) {
        const teamIDx = teamsSorted.indexOf(team);
        if (teamIDx >= 0) {
          teamsSorted.splice(teamIDx, 1);
        }
      }
      this.skipDivisionRank = true;
      for (const team of topTeams.reverse()) {
        teamsSorted.unshift(team);
      }
      teamsSorted = Enumerable.from(teamsSorted)
        .where((p) => p !== undefined)
        .toArray();
      for (let i = 0; i < teamsSorted.length; i++) {
        const team = teamsSorted[i];
        team.conferenceRank = i + 1;
      }
      // end top teams check
    }
  }

  rankDivisions(divisions: Array<Enumerable.IGrouping<string, Team>>) {
    const topTeams = { North: [], East: [], South: [], West: [] };
    for (const division of divisions) {
      for (const team of division.getSource()) {
        team.draftOrderRankMethod = null;
        team.rankMethod = -1;
      }
      for (let i = 0; i < 4; i++) {
        const top = this.divisionPickTop(division.getSource(), false);
        if (top) {
          division.getSource().splice(division.getSource().indexOf(top), 1);
          topTeams[top.division].push(top);
        }
      }
    }

    return topTeams;
  }
  divisionPickBottom(teamsInADivision: Team[]) {
    if(teamsInADivision.length == 0){
      return;
    }
    const bottom = Helpers.worstDivPctNaN(teamsInADivision);
    if (bottom.length === 1) {
      return bottom[0];
    } else {
      return this.draftOrderDivisionTieBreaker(bottom);
    }
  }

  divisionPickTop(teamsInADivision: Array<Team>, isWildcard: boolean): Team {
    if(teamsInADivision.length == 0){
      return;
    }
    const top = Helpers.bestPctNaN(teamsInADivision);
    if (top.length === 1) {
      return top[0];
    } else {
      return this.divisionTieBreaker(top, isWildcard);
    }
  }

  divisionTieBreaker(teams: Array<Team>, isWildcard: boolean) {
    let remaining = this.HtHDivision(teams);
    // Do division
    if (remaining.length === 1) {
      if (!this.isDraftOrderRanking) {
        remaining[0].rankMethod = RankMethod.hth;
      }
      return remaining[0];
    } else if (remaining.length !== teams.length) {
      return this.divisionTieBreaker(remaining, isWildcard);
    }
    // div
    remaining = Helpers.bestDivPctNaN(remaining);
    if (remaining.length === 1) {
      if (!this.isDraftOrderRanking) {
        remaining[0].rankMethod = RankMethod.divPct;
      }
      return remaining[0];
    } else if (remaining.length !== teams.length) {
      return this.divisionTieBreaker(remaining, isWildcard);
    }
    // common
    // Div CG never require 4 games
    remaining = Helpers.bestCommonPct(
      remaining,
      this.leagueSettings,
      this.userSettings.userConfig,
      false
    );
    if (remaining.length === 1) {
      if (!this.isDraftOrderRanking) {
        remaining[0].rankMethod = RankMethod.commonPct;
      }
      return remaining[0];
    } else if (remaining.length !== teams.length) {
      return this.divisionTieBreaker(remaining, isWildcard);
    }
    // conf
    remaining = Helpers.bestConfPctNaN(remaining);
    if (remaining.length === 1) {
      if (!this.isDraftOrderRanking) {
        remaining[0].rankMethod = RankMethod.confPct;
      }
      return remaining[0];
    } else if (remaining.length !== teams.length) {
      return this.divisionTieBreaker(remaining, isWildcard);
    }
    // sov
    remaining = Helpers.bestSOV(remaining);
    if (remaining.length === 1) {
      if (!this.isDraftOrderRanking) {
        remaining[0].rankMethod = RankMethod.sov;
      }
      return remaining[0];
    } else if (remaining.length !== teams.length) {
      return this.divisionTieBreaker(remaining, isWildcard);
    }
    // sos
    remaining = Helpers.bestSOS(remaining);
    if (remaining.length === 1) {
      if (!this.isDraftOrderRanking) {
        remaining[0].rankMethod = RankMethod.sos;
      }
      return remaining[0];
    } else if (remaining.length !== teams.length) {
      return this.divisionTieBreaker(remaining, isWildcard);
    }
    if(this.userSettings.userConfig.nfl.enableScoring) {
      remaining = Helpers.bestCombinedPfPaRankConference(remaining);
      if (remaining.length === 1) {
        if (!this.isDraftOrderRanking) {
          remaining[0].tiebreakers.Explanations.push(
            `Conference Combined PF+PA Ranking (${remaining[0].conferenceCombinedRanking}) over: ${teams
              .filter((p) => p.teamName != remaining[0].teamName)
              .map((p) => `${p.teamName} (${p.conferenceCombinedRanking})`)}`
          );
          remaining[0].rankMethod = RankMethod.combinedRankingConference;
        }
        return remaining[0];
      } else if (remaining.length != teams.length) {
        return this.confTiebreaker(remaining, isWildcard);
      }

      remaining = Helpers.bestCombinedPfPaRankLeague(remaining);
      if (remaining.length === 1) {
        if (!this.isDraftOrderRanking) {
          remaining[0].rankMethod = RankMethod.combinedRankingLeague;
          remaining[0].tiebreakers.Explanations.push(
            `Combined PF+PA Ranking (${remaining[0].leagueCombinedRanking}) over: ${teams
              .filter((p) => p.teamName != remaining[0].teamName)
              .map((p) => `${p.teamName} (${p.leagueCombinedRanking})`)}`
          );
        }
        return remaining[0];
      } else if (remaining.length != teams.length) {
        return this.confTiebreaker(remaining, isWildcard);
      }
    }
    else{
      for(let team of teams){
        team.tiebreakers.Explanations.push('WARNING: scoring is disabled, some tiebreakers are skipped. Please enable scoring for exhaustive tiebreakers')
      }
    }
    return this.coinFlip(teams);
  }

  draftOrderDivisionTieBreaker(teams: Team[]) {
    let remaining = this.HtHDivisionBottom(teams);
    //Do Division
    if (remaining.length == 1) {
      return remaining[0];
    } else if (remaining.length != teams.length) {
      return this.draftOrderDivisionTieBreaker(remaining);
    }
    //div
    remaining = Helpers.worstDivPctNaN(remaining);
    if (remaining.length == 1) {
      return remaining[0];
    } else if (remaining.length != teams.length) {
      return this.draftOrderDivisionTieBreaker(remaining);
    }
    //common
    remaining = Helpers.worstCommonPct(
      remaining,
      this.leagueSettings,
      this.userSettings.userConfig,
      true
    );
    if (remaining.length == 1) {
      return remaining[0];
    } else if (remaining.length != teams.length) {
      return this.draftOrderDivisionTieBreaker(remaining);
    }
    //conf
    remaining = Helpers.worstConfPctNaN(remaining);
    if (remaining.length == 1) {
      return remaining[0];
    } else if (remaining.length != teams.length) {
      return this.draftOrderDivisionTieBreaker(remaining);
    }
    //sov
    remaining = Helpers.worstSOV(remaining);
    if (remaining.length == 1) {
      return remaining[0];
    } else if (remaining.length != teams.length) {
      return this.draftOrderDivisionTieBreaker(remaining);
    }
    //sos
    remaining = Helpers.worstSOS(remaining);
    if (remaining.length == 1) {
      return remaining[0];
    } else if (remaining.length != teams.length) {
      return this.draftOrderDivisionTieBreaker(remaining);
    }

    return this.draftOrderCoinFlip(teams);
  }

  HtHDivision(teams: Array<Team>) {
    const games = {};
    // tslint:disable-next-line:forin
    for (const left of teams) {
      games[left.teamAbbreviation] = [0, 0, 0];
      for (const right of teams) {
        if (left !== right) {
          games[right.teamAbbreviation] = [0, 0, 0];
        }
      }
    }

    const gamesSeen = {};
    for (const left of teams) {
      for (const right of teams) {
        if (left !== right) {
          const hthGames = Helpers.getHTHDivisionGames(
            left,
            right,
            this.leagueSettings
          );
          // There will be 2 of these
          if (hthGames.length > 0) {
            if (hthGames[0].selectionMade) {
              const game = hthGames[0];
              if (gamesSeen[game.gameScheduleNum]) {
                continue;
              } else {
                gamesSeen[game.gameScheduleNum] = true;
              }
              if (
                game.teamThatWon &&
                game.teamThatWon &&
                game.teamThatWon === left
              ) {
                games[left.teamAbbreviation][0]++;
                games[right.teamAbbreviation][1]++;
              }
              if (game.teamThatWon && game.teamThatWon === right) {
                games[right.teamAbbreviation][0]++;
                games[left.teamAbbreviation][1]++;
              }
              if (game.selectionType === SelectionType.tie) {
                games[right.teamAbbreviation][2]++;
                games[left.teamAbbreviation][2]++;
              }
            }
            if (hthGames[1] && hthGames[1].selectionMade) {
              const game = hthGames[1];
              if (gamesSeen[game.gameScheduleNum]) {
                continue;
              } else {
                gamesSeen[game.gameScheduleNum] = true;
              }
              if (game.teamThatWon && game.teamThatWon === left) {
                games[left.teamAbbreviation][0]++;
                games[right.teamAbbreviation][1]++;
              }
              if (game.teamThatWon && game.teamThatWon === right) {
                games[right.teamAbbreviation][0]++;
                games[left.teamAbbreviation][1]++;
              }
              if (game.selectionType === SelectionType.tie) {
                games[right.teamAbbreviation][2]++;
                games[left.teamAbbreviation][2]++;
              }
            }
          }
        }
      }
    }
    for (const team of teams) {
      team.hthDivCalcPct = Helpers.calcPct(
        games[team.teamAbbreviation][0],
        games[team.teamAbbreviation][1],
        games[team.teamAbbreviation][2]
      );
    }
    const groups = Enumerable.from(teams)
      .orderByDescending((p) => p.hthDivCalcPct)
      .groupBy((p) => p.hthDivCalcPct)
      .toArray();
    const remaining = groups[0].getSource();
    if (groups.length > 1 && !this.isDraftOrderRanking) {
      const hthbeaten = [],
        temp = [];

      for (const item of groups) {
        for (const team of item.getSource()) {
          hthbeaten.push(team.teamAbbreviation);
        }
      }

      for (const teamRem of remaining) {
        teamRem.tiebreakers["DivHTH"] = [];
      }

      for (const teamRem of remaining) {
        for (const teamName of hthbeaten) {
          temp.push(teamName);
        }
        const hthEnumerable = Enumerable.from(temp).distinct().toArray();
        for (const teamName of hthEnumerable) {
          teamRem.tiebreakers["DivHTH"].push(
            `${teamName} (${games[teamName][0]}-${games[teamName][1]}${
              games[teamName][2] ? "-" + games[teamName][2] : ""
            })`
          );
        }
        teamRem.tiebreakers["DivHTH"] = Enumerable.from(
          teamRem.tiebreakers["DivHTH"]
        )
          .distinct()
          .toArray();
      }
    }

    return remaining;
  }
  HtHDivisionBottom(teams: Team[]) {
    const games = {};
    // tslint:disable-next-line:forin
    for (const left of teams) {
      games[left.teamAbbreviation] = [0, 0, 0];
      for (const right of teams) {
        if (left !== right) {
          games[right.teamAbbreviation] = [0, 0, 0];
        }
      }
    }

    const gamesSeen = {};
    for (const left of teams) {
      for (const right of teams) {
        if (left !== right) {
          const hthGames = Helpers.getHTHDivisionGames(
            left,
            right,
            this.leagueSettings
          );
          // There will be 2 of these
          if (hthGames.length > 0) {
            if (hthGames[0].selectionMade) {
              const game = hthGames[0];
              if (gamesSeen[game.gameScheduleNum]) {
                continue;
              } else {
                gamesSeen[game.gameScheduleNum] = true;
              }
              if (
                game.teamThatWon &&
                game.teamThatWon &&
                game.teamThatWon === left
              ) {
                games[left.teamAbbreviation][0]++;
                games[right.teamAbbreviation][1]++;
              }
              if (game.teamThatWon && game.teamThatWon === right) {
                games[right.teamAbbreviation][0]++;
                games[left.teamAbbreviation][1]++;
              }
              if (game.selectionType === SelectionType.tie) {
                games[right.teamAbbreviation][2]++;
                games[left.teamAbbreviation][2]++;
              }
            }
            if (hthGames[1] && hthGames[1].selectionMade) {
              const game = hthGames[1];
              if (gamesSeen[game.gameScheduleNum]) {
                continue;
              } else {
                gamesSeen[game.gameScheduleNum] = true;
              }
              if (game.teamThatWon && game.teamThatWon === left) {
                games[left.teamAbbreviation][0]++;
                games[right.teamAbbreviation][1]++;
              }
              if (game.teamThatWon && game.teamThatWon === right) {
                games[right.teamAbbreviation][0]++;
                games[left.teamAbbreviation][1]++;
              }
              if (game.selectionType === SelectionType.tie) {
                games[right.teamAbbreviation][2]++;
                games[left.teamAbbreviation][2]++;
              }
            }
          }
        }
      }
    }
    for (const team of teams) {
      team.hthDivCalcPct = Helpers.calcPct(
        games[team.teamAbbreviation][0],
        games[team.teamAbbreviation][1],
        games[team.teamAbbreviation][2]
      );
    }
    const groups = Enumerable.from(teams)
      .orderBy((p) => p.hthDivCalcPct)
      .groupBy((p) => p.hthDivCalcPct)
      .toArray();
    const remaining = groups[0].getSource();
    if (groups.length > 1) {
      const hthbeaten = [],
        temp = [];

      for (const item of groups) {
        for (const team of item.getSource()) {
          hthbeaten.push(team.teamAbbreviation);
        }
      }
    }

    return remaining;
  }
  coinFlip(teams) {
    let coinFlipSum = 0;
    for (const team of teams) {
      coinFlipSum += Math.random() * this.leagueSettings.numRegularSeasonGames;
    }
    const luckyTeam: Team = teams[Math.floor(coinFlipSum % teams.length)];
    if (!this.isDraftOrderRanking) {
      luckyTeam.rankMethod = RankMethod.coinFlip;
    }
    return luckyTeam;
  }

  draftOrderCoinFlip(teams: Team[]) {
    let coinFlipSum = 0;
    for (const team of teams) {
      coinFlipSum += Math.random() * this.leagueSettings.numRegularSeasonGames;
    }
    const luckyTeam: Team = teams[Math.floor(coinFlipSum % teams.length)];
    return luckyTeam;
  }
  updateRecordVsPlayoffTeams(teams: Array<Team>){
    for (const team of teams) {
      const PT = { Wins: 0, Losses: 0, Ties: 0 };
      for (const game of team.schedule.filter(g => g.selectionType !== SelectionType.none && g.selectionType !== SelectionType.noContest
        && g.week <= this.leagueSettings.numRegularSeasonWeeks)) {
          if(game.selectionType == SelectionType.tie){
            const home: Team = game.home;
            const away: Team = game.away;
            const isHome = home === team;
            if(away.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference){
              PT.Ties++;
            }
            continue;
          }
          if(game.teamSelected === team.teamAbbreviation){ // this team won
            if(game?.teamThatLost?.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference){
              PT.Wins++;
            }
          }else{ //this team lost
            if(game?.teamThatWon?.conferenceRank <= this.leagueSettings.numPlayoffTeamsPerConference){
              PT.Losses++;
            }
          }
        }

      team.ptRec = [PT.Wins, PT.Losses, PT.Ties];
      team.PTRecord = Helpers.calcWLT(PT.Wins, PT.Losses, PT.Ties);
    }
  }
  setSovAndSos(teams: Array<Team>) {
    for (const team of teams) {
      const SOS = { Wins: 0, Losses: 0, Ties: 0 };
      const SOV = { Wins: 0, Losses: 0, Ties: 0 };
      const SOL = { Wins: 0, Losses: 0, Ties: 0 };
      for (const game of team.schedule.filter(g => (this.userSettings.userConfig.nfl.sosMethodology == StrengthOfScheduleMethodology.GamesMustBePlayed ? g.selectionType !== SelectionType.none : true)
                                                  && g.week <= this.leagueSettings.numRegularSeasonWeeks)) {
        let multiplier = 0;
        const home: Team = game.home;
        const away: Team = game.away;
        const isHome = home === team;
        // This makes it so the team that "won" gets multiplied
        if (game.teamSelected === team.teamAbbreviation) {
          multiplier = 1;
        }else{ // we care about when they lose for SOL
          if(game.selectionType != SelectionType.tie){
            if (isHome) {
              SOL.Wins += away.gameRecords.allRecord.wins;
              SOL.Losses += away.gameRecords.allRecord.losses;
              SOL.Ties += away.gameRecords.allRecord.ties;
            } else {
              SOL.Wins += home.gameRecords.allRecord.wins;
              SOL.Losses += home.gameRecords.allRecord.losses;
              SOL.Ties += home.gameRecords.allRecord.ties;
            }
          }
        }
        // the team we're analyzing is the home team, so the opponent is the away team
        if (isHome) {
          SOV.Wins += away.gameRecords.allRecord.wins * multiplier;
          SOV.Losses += away.gameRecords.allRecord.losses * multiplier;
          SOV.Ties += away.gameRecords.allRecord.ties * multiplier;

          SOS.Wins += away.gameRecords.allRecord.wins;
          SOS.Losses += away.gameRecords.allRecord.losses;
          SOS.Ties += away.gameRecords.allRecord.ties;
        } else {
          SOV.Wins += home.gameRecords.allRecord.wins * multiplier;
          SOV.Losses += home.gameRecords.allRecord.losses * multiplier;
          SOV.Ties += home.gameRecords.allRecord.ties * multiplier;

          SOS.Wins += home.gameRecords.allRecord.wins;
          SOS.Losses += home.gameRecords.allRecord.losses;
          SOS.Ties += home.gameRecords.allRecord.ties;
        }
      }
      team.sosRec = [SOS.Wins, SOS.Losses, SOS.Ties];
      team.sovRec = [SOV.Wins, SOV.Losses, SOV.Ties];
      team.solRec = [SOL.Wins, SOL.Losses, SOL.Ties];
      team.SOS = Helpers.calcWLT(SOS.Wins, SOS.Losses, SOS.Ties);
      team.SOV = Helpers.calcWLT(SOV.Wins, SOV.Losses, SOV.Ties);
      team.SOL = Helpers.calcWLT(SOL.Wins, SOL.Losses, SOL.Ties);
    }
    //rank pf and cpf
    let pfSorted = Enumerable.from(teams).orderByDescending(p => p.pf).groupBy(p => p.pf).toArray();
    let rank = 1;
    for(let group of pfSorted){
      for(let team of group){
        team.pfRank = rank;
      }
      rank += group.count();
    }
    rank = 1;
    let cPfSorted = Enumerable.from(teams).where(p => p.conference == "NFC").orderByDescending(p => p.pf).groupBy(p => p.pf).toArray();
    for(let group of cPfSorted){
      for(let team of group){
        team.cpfRank = rank;
      }
      rank += group.count();
    }
    rank = 1;
    cPfSorted = Enumerable.from(teams).where(p => p.conference == "AFC").orderByDescending(p => p.pf).groupBy(p => p.pf).toArray();
    for(let group of cPfSorted){
      for(let team of group){
        team.cpfRank = rank;
      }
      rank += group.count();
    }

    let paSorted = Enumerable.from(teams).orderBy(p => p.pa).groupBy(p => p.pa).toArray();
    rank = 1;
    for(let group of paSorted){
      for(let team of group){
        team.paRank = rank;
      }
      rank += group.count();
    }
    rank = 1;
    let cPaSorted = Enumerable.from(teams).where(p => p.conference == "NFC").orderBy(p => p.pa).groupBy(p => p.pa).toArray();
    for(let group of cPaSorted){
      for(let team of group){
        team.cpaRank = rank;
      }
      rank += group.count();
    }
    rank = 1;
    cPaSorted = Enumerable.from(teams).where(p => p.conference == "AFC").orderBy(p => p.pa).groupBy(p => p.pa).toArray();
    for(let group of cPaSorted){
      for(let team of group){
        team.cpaRank = rank;
      }
      rank += group.count();
    }

    for(let team of teams){
      team.conferenceCombinedRanking = team.cpaRank + team.cpfRank;
      team.leagueCombinedRanking = team.paRank + team.pfRank;
    }
  }

  wildcardRanker(divisions, top, teams) {
    if (teams[0] && teams[0].length) {
      teams = [].concat.apply([], teams);
    }
    let best = this.conferencePickTop(teams, false, true);
    top.push(best);
    teams.splice(teams.indexOf(best), 1);
    best = this.conferencePickTop(teams, false, true);
    top.push(best);
    return top;
  }
  rankRestOfConference(topTeams: Array<Team>, teams: Array<Team>) {
    const top = [];
    const remaining = teams.slice(0);
    for (let i = 0; i < topTeams.length; i++) {
      top.push(topTeams[i]);
      remaining.splice(remaining.indexOf(topTeams[i]), 1);
    }
    for (const team of remaining) {
      if (!this.isDraftOrderRanking) {
        team.rankMethod = -1;
      }
    }
    while (remaining.length > 0) {
      const best = this.conferencePickTop(
        remaining,
        null,
        this.userSettings.userConfig.nfl.treatNonPlayoffTeamsLikeWildcards
      );
      top.push(best);
      remaining.splice(remaining.indexOf(best), 1);
    }
    return top;
  }
  conferenceRanker(teams: Array<Team>, preventRankOverride?: boolean) {
    if (!Enumerable.from(teams).any((p) => p === undefined)) {
      const top = [];
      const remaining = teams.slice(0);
      for (let i = 0; i < 4; i++) {
        const best = this.conferencePickTop(
          remaining,
          preventRankOverride,
          true
        );
        top.push(best);
        remaining.splice(remaining.indexOf(best), 1);
      }
      return top;
    } else {
      return;
    }
  }
  conferencePickTop(
    teams: Array<Team>,
    preventRankOverride?: boolean,
    isWildcard?: boolean
  ) {
    teams = [].concat.apply([], teams);
    if (!Enumerable.from(teams).any((p) => p === undefined)) {
      const top = Helpers.bestPctNaN(teams);
      if (top.length === 1) {
        if (
          top[0].skipRanking === false ||
          top[0].rankMethod === RankMethod.unknown ||
          (top[0].rankMethod <= 10 && top[0].rankMethod >= 5)
        ) {
          if (
            !this.isDraftOrderRanking && !preventRankOverride &&
            top[0].rankMethod === RankMethod.unknown) {
            top[0].rankMethod = RankMethod.allPct;
          }
        }
        if(top[0].rankMethod === RankMethod.unknown){
          top[0].rankMethod = RankMethod.allPct;
        }
        return top[0];
      } else {
        return this.confTiebreaker(top, isWildcard);
      }
    }
    return;
  }

  confTiebreaker(teams: Array<Team>, isWildcard?: boolean) {
    if (!Enumerable.from(teams).any((p) => p === undefined)) {
      let remaining = [];
      // if we can break with a division, we need to
      if (
        Enumerable.from(teams)
          .groupBy((p) => p.division)
          .toArray().length !== teams.length
      ) {
        // Slice it up into divisions, order by the number of divisons. This will let us eliminate ties easier
        const divisions = Enumerable.from(teams)
          .groupBy((p) => p.division)
          .orderByDescending((p) => p.getSource().length)
          .toArray();
        const top = [];
        for (const division of divisions) {
          const item = this.divisionPickTop(division.getSource(), isWildcard);
          item.skipRanking = true;
          top.push(item);
        }
        remaining = Enumerable.from(top)
          .where((p) => p !== undefined)
          .toArray();
        if (remaining.length === 1) {
          return remaining[0];
        }
        if (remaining.length === 0) {
          return;
        }
        for (const team of remaining) {
          team.RankMethod = RankMethod.unknown;
        }
      } else {
        remaining = teams.slice(0);
      }
      remaining = this.HtHConference(remaining);
      if (remaining.length === 1) {
        if (!this.isDraftOrderRanking) {
          remaining[0].rankMethod = RankMethod.hth;
        }
        return remaining[0];
      } else if (remaining.length !== teams.length) {
        return this.confTiebreaker(remaining, isWildcard);
      }
      // conf
      remaining = Helpers.bestConfPctNaN(remaining);
      if (remaining.length === 1) {
        if (remaining[0].rankMethod === -1) {
          if (!this.isDraftOrderRanking) {
            remaining[0].rankMethod = RankMethod.confPct;
          }
        }
        return remaining[0];
      } else if (remaining.length !== teams.length) {
        return this.confTiebreaker(remaining, isWildcard);
      }
      // common
      remaining = Helpers.bestCommonPct(
        remaining,
        this.leagueSettings,
        this.userSettings.userConfig,
        isWildcard
      );
      if (remaining.length === 1) {
        if (!this.isDraftOrderRanking) {
          remaining[0].rankMethod = RankMethod.commonPct;
        }
        return remaining[0];
      } else if (remaining.length !== teams.length) {
        return this.confTiebreaker(remaining, isWildcard);
      }

      // sov
      remaining = Helpers.bestSOV(remaining);
      if (remaining.length === 1) {
        if (!this.isDraftOrderRanking) {
          remaining[0].rankMethod = RankMethod.sov;
        }
        return remaining[0];
      } else if (remaining.length !== teams.length) {
        return this.confTiebreaker(remaining, isWildcard);
      }
      // sos
      remaining = Helpers.bestSOS(remaining);
      if (remaining.length === 1) {
        if (!this.isDraftOrderRanking) {
          remaining[0].rankMethod = RankMethod.sos;
        }
        return remaining[0];
      } else if (remaining.length !== teams.length) {
        return this.confTiebreaker(remaining, isWildcard);
      }

      if(this.userSettings.userConfig.nfl.enableScoring){
        remaining = Helpers.bestCombinedPfPaRankConference(remaining);
        if (remaining.length === 1) {
          if (!this.isDraftOrderRanking) {
            remaining[0].tiebreakers.Explanations.push(
              `Conference Combined PF+PA Ranking (${remaining[0].conferenceCombinedRanking}) over: ${teams
                .filter((p) => p.teamName != remaining[0].teamName)
                .map((p) => `${p.teamName} (${p.conferenceCombinedRanking})`)}`
            );

            remaining[0].rankMethod = RankMethod.combinedRankingConference;
          }
          return remaining[0];
        } else if (remaining.length != teams.length) {
          return this.confTiebreaker(remaining, isWildcard);
        }

        remaining = Helpers.bestCombinedPfPaRankLeague(remaining);
        if (remaining.length === 1) {
          if (!this.isDraftOrderRanking) {
            remaining[0].tiebreakers.Explanations.push(
              `Combined PF+PA Ranking (${remaining[0].leagueCombinedRanking}) over: ${teams
                .filter((p) => p.teamName != remaining[0].teamName)
                .map((p) => `${p.teamName} (${p.leagueCombinedRanking})`)}`
            );

            remaining[0].rankMethod = RankMethod.combinedRankingLeague;
          }
          return remaining[0];
        } else if (remaining.length != teams.length) {
          return this.confTiebreaker(remaining, isWildcard);
        }

        remaining = Helpers.bestNetPointsConferenceGames(teams);
        if (remaining.length === 1) {
          if (!this.isDraftOrderRanking) {
            remaining[0].tiebreakers.Explanations.push(
              `Best Net Points (conference games) (${remaining[0].cpf - remaining[0].cpa}) over: ${teams
                .filter((p) => p.teamName != remaining[0].teamName)
                .map((p) => `${p.teamName} (${p.cpf - p.cpa})`)}`
            );

            remaining[0].rankMethod = RankMethod.bestNetPointsConferenceGames;
          }
          return remaining[0];
        } else if (remaining.length != teams.length) {
          return this.confTiebreaker(remaining, isWildcard);
        }

        remaining = Helpers.bestNetPointsAllGames(teams);
        if (remaining.length === 1) {
          if (!this.isDraftOrderRanking) {
            remaining[0].tiebreakers.Explanations.push(
              `Best Net Points (all games) (${remaining[0].pf - remaining[0].pa}) over: ${teams
                .filter((p) => p.teamName != remaining[0].teamName)
                .map((p) => `${p.teamName} (${p.pf - p.pa})`)}`
            );

            remaining[0].rankMethod = RankMethod.bestNetPointsAllGames;
          }
          return remaining[0];
        } else if (remaining.length != teams.length) {
          return this.confTiebreaker(remaining, isWildcard);
        }
      }
      else{
        for(let team of remaining){
          team.tiebreakers.Explanations.push('WARNING: scoring is disabled, some tiebreakers are skipped. Please enable scoring for exhaustive tiebreakers')
        }
      }

      return this.coinFlip(remaining);
    }
  }

  draftorderTiebreaker(teams: Array<Team>) {
    if (!Enumerable.from(teams).any((p) => p === undefined)) {
      let remaining = teams.slice(0);
      // sos
      remaining = Helpers.worstSOS(remaining);
      if (remaining.length === 1) {
        return remaining[0];
      } else if (remaining.length !== teams.length) {
        return this.draftorderTiebreaker(remaining);
      }

      // if we can break with a division, we need to
      if (
        Enumerable.from(teams)
          .groupBy((p) => p.division)
          .toArray().length !== teams.length
      ) {
        // Slice it up into divisions, order by the number of divisons. This will let us eliminate ties easier
        const divisions = Enumerable.from(teams)
          .groupBy((p) => p.division)
          .orderByDescending((p) => p.getSource().length)
          .toArray();
        const top = [];
        for (const division of divisions) {
          const item = this.divisionPickBottom(division.getSource());
          item.skipRanking = true;
          top.push(item);
        }
        remaining = Enumerable.from(top)
          .where((p) => p !== undefined)
          .toArray();
        if (remaining.length === 1) {
          return remaining[0];
        }
        if (remaining.length === 0) {
          return;
        }
        for (const team of remaining) {
          team.rankMethod = RankMethod.unknown;
        }
      } else {
        remaining = teams.slice(0);
      }
    }
  }

  HtHConference(teams: Array<Team>) {
    const games = {};
    for (const tempTeam of teams) {
      if (!tempTeam) {
        break;
      }
      for (const tempFoe of teams.slice(0)) {
        if (!tempFoe) {
          break;
        }
        if (tempTeam === tempFoe) {
          continue;
        }
        const schedule = Helpers.getGamesVsEachOther(
          tempTeam,
          tempFoe,
          this.leagueSettings
        );
        for (const game of schedule) {
          if (game.selectionMade) {
            if (game.selectionType !== SelectionType.tie) {
              games[
                game.away.teamAbbreviation + "-" + game.home.teamAbbreviation
              ] = game.selectionType;
            }
          }
        }
      }
    }

    let foe,
      i,
      j,
      k,
      l,
      len,
      len1,
      len2,
      len3,
      remainingTeams,
      sweepUnbroken,
      sweptUnbroken,
      team;
    // console.log("hth_conf: ");
    // console.log(teams);
    for (i = 0, len = teams.length; i < len; i++) {
      team = teams[i];
      if (!team) {
        break;
      }
      // console.log("hth checking sweep for: " + team().teamAbbreviation);
      sweepUnbroken = true;
      for (j = 0, len1 = teams.length; j < len1; j++) {
        foe = teams[j];
        if (!foe) {
          break;
        }
        if (!(foe !== team)) {
          continue;
        }
        // console.log("hth tempFoe: " + tempFoe().teamAbbreviation);
        // tempFoe wins && tempFoe wins
        if (
          games[team.teamAbbreviation + "-" + foe.teamAbbreviation] !==
            SelectionType.awayWin &&
          games[foe.teamAbbreviation + "-" + team.teamAbbreviation] !==
            SelectionType.homeWin
        ) {
          sweepUnbroken = false;
          break;
        }
      }
      if (sweepUnbroken) {
        // console.log(team().teamAbbreviation + " sweeps");
        const teamsSwept = [];
        const sweep = Enumerable.from(teams)
          .where((p: any) => p !== team)
          .toArray();
        for (const item of sweep) {
          teamsSwept.push(item.teamAbbreviation);
        }
        var sweeps = [];
        if (!this.isDraftOrderRanking) {
          sweeps.push(teamsSwept.toString().trim());
        }
        team.tiebreakers["Sweeps"] = [...new Set(sweeps)]
        return [team];
      }
    }
    for (k = 0, len2 = teams.length; k < len2; k++) {
      team = teams[k];
      sweptUnbroken = true;
      for (l = 0, len3 = teams.length; l < len3; l++) {
        foe = teams[l];
        if (!team || !foe) {
          break;
        }
        if (foe !== team) {
          if (
            games[team.teamAbbreviation + "-" + foe.teamAbbreviation] !==
              SelectionType.homeWin &&
            games[foe.teamAbbreviation + "-" + team.teamAbbreviation] !==
              SelectionType.awayWin
          ) {
            sweptUnbroken = false;
            break;
          }
        }
      }
      if (sweptUnbroken) {
        remainingTeams = teams.slice(0);
        remainingTeams.splice(remainingTeams.indexOf(team), 1);
        return remainingTeams;
      }
    }
    return teams;
  }
}
